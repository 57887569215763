'use client';

import * as React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import { random, sampleOne } from '@/utils';
import useSound from '@/hooks/use-sound';

import IconWrapper from './IconWrapper';

const FLAP_SPRITE = {
  first: [72, 500],
  second: [625, 500],
  third: [1182, 500],
  fourth: [1769, 500],
};

const FLAP_KEYS = Object.keys(
  FLAP_SPRITE
) as (keyof typeof FLAP_SPRITE)[];

export function IconBluesky({
  size = 20,
  isBooped,
}: {
  size?: number;
  isBooped: boolean;
}) {
  const leftWingStyle = useSpring({
    transform: isBooped ? 'rotateY(-48deg)' : 'rotateY(0deg)',
    config: {
      tension: 250,
      friction: random(8, 20),
    },
    delay: sampleOne([0, 0, 0, 25, 50]),
  });
  const rightWingStyle = useSpring({
    transform: isBooped ? 'rotateY(-64deg)' : 'rotateY(0deg)',
    config: {
      tension: 250,
      friction: random(15, 27),
    },
    delay: sampleOne([0, 25, 50, 50, 50, 50, 50, 75]),
  });

  return (
    <Svg
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Wing
        d="M12 8.56675C12 8.56675 7.87619 0.22183 2.70999 1.05903C-0.906331 1.64506 2.17634 10.571 3.21796 12.0702C4.25958 13.5694 6.32331 12.5684 6.32331 12.5684C6.32331 12.5684 0.640808 14.0699 5.29012 18.5743C9.73023 22.876 12 16.0718 12 16.0718"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={leftWingStyle}
      />
      <Wing
        d="M12.1523 8.62388C12.1523 8.62388 16.2762 0.278959 21.4424 1.11616C25.0587 1.70219 21.976 10.6281 20.9344 12.1273C19.8928 13.6265 17.829 12.6255 17.829 12.6255C17.829 12.6255 23.5115 14.127 18.8622 18.6314C14.4221 22.9331 12.1523 16.129 12.1523 16.129"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={rightWingStyle}
      />
    </Svg>
  );
}

function WrappedIconBluesky(props: { size?: number }) {
  const [playRaw] = useSound('/sounds/flap.mp3', {
    sprite: FLAP_SPRITE,
    volume: 0.2,
  });

  return (
    <IconWrapper
      alt="Jerica's BlueSky profile"
      {...props}
      href="https://bsky.app/profile/joshwcomeau.com"
      boopTiming={190}
      onMouseEnter={() => {
        const key = sampleOne(FLAP_KEYS);
        playRaw({ id: key });
      }}
    >
      {({ isBooped }) => <IconBluesky isBooped={isBooped} />}
    </IconWrapper>
  );
}

const Svg = styled.svg`
  display: block;
  overflow: visible;
`;

const Wing = styled(animated.path)`
  transform-origin: 50% 50%;
`;

export default WrappedIconBluesky;
